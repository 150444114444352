import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "../components/ui/Card";
import { Expand, KeyboardArrowDown } from "@mui/icons-material";
import BoxCenter from "../components/ui/BoxCenter";
import messageImage from "../assets/img/message-icon.png"

const _faqs = [
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    },
    {
        header: 'DF Gdf hgfd hgf hdfg ds gsdg ',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.'
    }
]

const SupportView = () => {
    return (
        <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                {
                    _faqs.map((item, index) => {
                        return (
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowDown />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    {item.header}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item.content}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                <Card>
                    <BoxCenter>
                        <img src={messageImage} />
                    </BoxCenter>
                    <TextField
                        label="Your message"
                        multiline
                        fullWidth
                        rows={6}
                        maxRows={6}
                        sx={{ marginTop: '30px', marginBottom: '30px' }}
                    />
                    <BoxCenter style={{ marginBottom: '30px' }}>
                        <Button variant="contained">Send</Button>
                    </BoxCenter>
                </Card>
            </Grid>
        </Grid>
    )
}
export default SupportView;