import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

const ModalCloseButton = ({ setState }) => {
    const modalHide=()=>{
        if(setState){
            setState(false);
        }
    }
    return (
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'right',mb:5}}>
            <IconButton aria-label="delete" onClick={modalHide}>
                <Close />
            </IconButton>
        </Box>
    )
}
export default ModalCloseButton;