import { Box, Button, FormControl, Grid2, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "../components/ui/Card";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { Link } from "react-router-dom";

const LoginView = () => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };
    return (
        <>
            <Grid container sx={{ minHeight: '100vh', width: '100vw', p: 0, m: 0 }} spacing={0}>
                <Grid size={{ xs: 12, sm: 6, md: 8, lg: 9 }} sx={{ p: 0, m: 0, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-ui-ux-background-illustrated_23-2149050183.jpg?t=st=1730814609~exp=1730818209~hmac=41eb99f144a8fcf15aeb625f916709dfec37459f945c5a7db53c2824ad4dfcf1&w=1380)' }}>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} sx={{ m: 0, background: '#fff', p: 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 7 }}>
                        <Box sx={{ maxWidth: '460px', width: '100%' }}>
                            <h1 style={{ textAlign: 'center' }}>Logo</h1>
                            <h4 style={{ textAlign: 'center' }}>Welkom bij ABC123</h4>
                            <Box sx={{ pl: 3, pr: 3 }}>
                                <TextField label="E-mail" variant="standard" sx={{ width: '100%', mb: 3 }} />
                                <FormControl sx={{ width: '100%', mb: 5 }} variant="standard">
                                    <InputLabel htmlFor="standard-adornment-password">Wachtwoord</InputLabel>
                                    <Input
                                        id="standard-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label={
                                                        showPassword ? 'hide the password' : 'display the password'
                                                    }
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    onMouseUp={handleMouseUpPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Box>
                            <Link to="/forgot-password" style={{ display: 'block', textAlign: 'center', width: '100%', marginBottom: '20px' }}>Forgot password</Link>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" component={Link} to="/index">Log in</Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}
export default LoginView;