import Grid from "@mui/material/Grid2";
import Card from "../components/ui/Card";

const UserView = () => {
    return (
        <Card>
            <Grid>
                
            </Grid>

        </Card>
    )
}
export default UserView;