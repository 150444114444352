import { Add } from "@mui/icons-material";
import { Box, Button, FormControl, InputLabel, Modal, NativeSelect, TextField, Typography } from "@mui/material";
import { useState } from "react";
import BoxCenter from "../../ui/BoxCenter";
import LawUploadFile from "../../elements/LawUploadFile";
import ModalCloseButton from "../../ui/ModalCloseButton";
import LawModal from "../../ui/LawModal";

const TicketAddStatus = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button variant="contained" sx={{ width: '100%', mb: 2, mt: 5 }} onClick={()=>setOpen(true)}><Add /> Durum Ekle</Button>
            <LawModal state={open} setState={setOpen}>
                <TextField id="standard-basic" label="Ticket Name" variant="standard" sx={{ width: '100%', mb: 3 }} />
                <TextField
                    id="standard-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    variant="standard"
                    sx={{ width: '100%', mb: 3 }}
                />
                <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Status
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <option value={10}>Status 1</option>
                        <option value={10}>Status 2</option>
                        <option value={10}>Status 3</option>
                        <option value={10}>Status 4</option>
                        <option value={10}>Status 5</option>
                    </NativeSelect>
                </FormControl>
                <Box>
                    <LawUploadFile />
                </Box>
                <BoxCenter>
                    <Box>
                        <Button variant="contained" sx={{ width: '100%', mb: 2, mt: 5 }}>Ekle</Button>
                    </Box>
                </BoxCenter>
            </LawModal>
        </>
    )
}
export default TicketAddStatus;