import { Alert, AlertTitle, Badge, IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Close } from "@mui/icons-material";

const _notifications = [
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'error'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'info'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'warning'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'info'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'success'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'error'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'error'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'info'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'warning'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'info'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'success'
    },
    {
        header: 'Notification header text',
        description: 'notification description area ....',
        date: '01.01.2000 00:00',
        type: 'error'
    }
]
const NotificationItems = () => {
    return (
        <>
            {
                _notifications.map((item, index) => {
                    return (
                        <Alert
                            key={index}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    
                                    onClick={() => { }}
                                >
                                    <Close fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            severity={item.type}
                        >
                            <AlertTitle>{item.header}</AlertTitle>
                            <Typography sx={{ p: 0 }}>{item.description}</Typography>
                            <Typography sx={{ fontSize: 12 }}>{item.date}</Typography>
                        </Alert>
                    )
                })
            }
        </>
    )
}
export default NotificationItems;

