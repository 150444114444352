import Card from "../../components/ui/Card";
import TableAccountantRegisters from "../../components/data/accountant/TableAccountantRegisters";

const AccountantRegistersView = () => {
    return (
        <Card>
            <TableAccountantRegisters />
        </Card>
    )
}
export default AccountantRegistersView;