import { Check, CheckCircle, CheckCircleOutline, Edit, Folder } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Tab, Typography } from "@mui/material";
import { useState } from "react";

const _items = [
    {
        status: 0,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 1,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 0,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 1,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 0,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 1,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 0,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 1,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 0,
        description: 'description',
        date: '01.01.2000'
    },
    {
        status: 1,
        description: 'description',
        date: '01.01.2000'
    }
]
const TodoList = () => {
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent:'space-between' }}>
                <Box>
                    <h5>Todo-lijst</h5>
                </Box>
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                    <IconButton aria-label="delete">
                        <CheckCircle />
                    </IconButton>
                    <IconButton aria-label="delete">
                        <Edit />
                    </IconButton>
                </Box>
            </Box>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {
                    _items.map((item, index) => {
                        return (
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        <CheckCircle />
                                    </IconButton>
                                }
                            >
                                <ListItemAvatar component={IconButton} sx={{cursor:'pointer'}}>
                                    <Avatar>
                                        <Folder />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.description}
                                    secondary={item.date}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>
        </>
    )
}
export default TodoList;