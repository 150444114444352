import Grid from "@mui/material/Grid2";
import Card from "../../components/ui/Card";
import ConsumerCard from "../../components/ui/ConsumerCard";
import { Box, Button, Divider, Stack } from "@mui/material";
import { Add, Autorenew, CalendarMonth, ModeEdit } from "@mui/icons-material";
import BoxCenter from "../../components/ui/BoxCenter";
import TicketRemove from "../../components/data/ticket/TicketRemove";
import TicketAddStatus from "../../components/data/ticket/TicketAddStatus";
const TicketView = () => {
    return (
        <>
            <Grid container spacing={1}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 3 }}>
                    <Card>
                        <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
                            <ConsumerCard />
                            <Divider sx={{ mb: 3 }} />
                            <Stack direction="row" spacing={2}>
                                <Box>
                                    <CalendarMonth />
                                </Box>
                                <Box><strong className="text-muted">Carate:</strong></Box>
                                <Box>
                                    <span className="d-block">create by user name</span>
                                    <small className="d-block text-muted">01.01.2025</small>
                                </Box>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Box>
                                    <Autorenew />
                                </Box>
                                <Box><strong className="text-muted">Update:</strong></Box>
                                <Box>
                                    <span className="d-block">update by user name</span>
                                    <small className="d-block text-muted">01.01.2025</small>
                                </Box>
                            </Stack>
                            <TicketAddStatus />
                            <TicketRemove />
                        </Box>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 9 }}>
                    <Card>
                        <BoxCenter>
                            <Box>
                                <TicketAddStatus />
                            </Box>
                        </BoxCenter>
                        <div className="finbyz-timeline">
                            <div class="wgl-row-animation">
                                <div data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid vc_row-has-fill wgl-row-animation">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div class="seofy_module_spacing">
                                                    <div class="spacing_size spacing_size-initial" style={{ height: '30px' }}></div>
                                                </div>
                                                <div class="seofy_module_time_line_vertical appear_anim">
                                                    <div class="time_line-item item_active item_show warning">
                                                        <div class="time_line-date_wrap">
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <h4 class="time_line-date">14.11.2019</h4>
                                                        </div>
                                                        <div class="time_line-content">
                                                            <h5 class="time_line-title">Header</h5>
                                                            <div class="time_line-descr">
                                                                Comment
                                                            </div>
                                                            <small class="d-block text-muted">User Name Surname (Role Name)</small>
                                                        </div>
                                                    </div>
                                                    <div class="time_line-item danger item_show">
                                                        <div class="time_line-date_wrap">
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <h4 class="time_line-date">14.11.2018</h4>
                                                        </div>
                                                        <div class="time_line-content">
                                                            <h5 class="time_line-title">Header</h5>
                                                            <div class="time_line-descr">
                                                                Comment
                                                            </div>
                                                            <small class="d-block text-muted">User Name Surname (Role Name)</small>
                                                        </div>
                                                    </div>
                                                    <div class="time_line-item info item_show">
                                                        <div class="time_line-date_wrap">
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <h4 class="time_line-date">14.11.2017</h4>
                                                        </div>
                                                        <div class="time_line-content">
                                                            <h5 class="time_line-title">Header</h5>
                                                            <div class="time_line-descr">
                                                                Comment
                                                            </div>
                                                            <small class="d-block text-muted">User Name Surname (Role Name)</small>
                                                        </div>
                                                    </div>
                                                    <div class="time_line-item secondary item_show">
                                                        <div class="time_line-date_wrap">
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <h4 class="time_line-date">14.11.2016</h4>
                                                        </div>
                                                        <div class="time_line-content">
                                                            <h5 class="time_line-title">Header</h5>
                                                            <div class="time_line-descr">
                                                                Comment
                                                            </div>
                                                            <small class="d-block text-muted">User Name Surname (Role Name)</small>
                                                        </div>
                                                    </div>
                                                    <div class="time_line-item primary item_show">
                                                        <div class="time_line-date_wrap">
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <div class="seofy_hexagon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 197.4">
                                                                    <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                            <h4 class="time_line-date">14.11.2015</h4>
                                                        </div>
                                                        <div class="time_line-content">
                                                            <h5 class="time_line-title">Header</h5>
                                                            <div class="time_line-descr">
                                                                Comment
                                                            </div>
                                                            <small class="d-block text-muted">User Name Surname (Role Name)</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="seofy_module_spacing responsive_active">
                                                    <div class="spacing_size spacing_size-initial" style={{ height: '76px' }}></div>
                                                    <div class="spacing_size spacing_size-tablet" style={{ height: '65px' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
export default TicketView;