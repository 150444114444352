import { Send } from "@mui/icons-material";
import { Avatar, Box, IconButton } from "@mui/material";
import { ContxAppData } from "../../../App";
import { useContext, useEffect } from "react";

const _messageItems = [
    {
        message: 'dfgdfgdfhf',
        date: '01.01.2000',
        isMy: false
    },
    {
        message: 'fd hfdgh dfhdgf jgfh jgh j',
        date: '01.01.2000',
        isMy: true
    }, {
        message: 'gfh jghk hdgfhjgf jghf jgh kghkgh kf kfg',
        date: '01.01.2000',
        isMy: false
    }, {
        message: 'dgf jgfh khk hjkgh kfghj fg',
        date: '01.01.2000',
        isMy: false
    }, {
        message: 'gd jghf khjgfhdg jgfh jghkhjfgjg jghf jrg jghjgfjgf jghf kh khj kghk gh',
        date: '01.01.2000',
        isMy: false
    }, {
        message: 'gf kghf kgfh jgfh jgf jghf khjkhj lhjkhf kj',
        date: '01.01.2000',
        isMy: true
    }, {
        message: 'gf kghf khjfjghf jgf jghf khf jdgfjgh khjkry ıyu kh kfgjdg kgfhkhj khf khf',
        date: '01.01.2000',
        isMy: true

    }, {
        message: 'gf jghf kkkkkj gfh khj kljhjg lghkghk',
        date: '01.01.2000',
        isMy: true
    },
    {
        message: 'g jghf kghkghkjghf khj kfghjgfh kh khgk',
        date: '01.01.2000',
        isMy: false
    }, {
        message: 'gfh khfjkhgfkjgfh kıtyuhkfhk hfk ghf jghfkty ukghf jdg jdgj gfj gfh kjgf',
        date: '01.01.2000',
        isMy: false
    }, {
        message: 'gf hjghf kghf jgfhk hg kgfjdg jgfh kjghf kgfkgf jdgjgfhjgfh jghkgh kgh kghk',
        date: '01.01.2000',
        isMy: false
    }, {
        message: 'gd jgfh kgh krf7ıghkgfjgdjfghkhfkjghf jghf jgfh jgfj gfhjhkghkfghkgf',
        date: '01.01.2000',
        isMy: true
    }, {
        message: 'gf hkfgkhfk h',
        date: '01.01.2000',
        isMy: false
    },
]

const MessageBody = () => {
    const { messageSelectedUser, setMessageSelectedUser } = useContext(ContxAppData);
    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', padding: '20px', background: '#fff' }}>
                <Box sx={{ display: { sm: 'block' } }}></Box>
                <Avatar alt="Cindy Baker" src="https://cdn-icons-png.flaticon.com/512/9187/9187604.png" />
                <Box sx={{ paddingLeft: '20px' }}>
                    <h5 style={{ margin: '0px' }}>{messageSelectedUser ?? '....'} </h5>
                    <small>Status</small>
                </Box>
            </Box>
            <Box className="law-message-body">
                {
                    _messageItems.map((item, index) => {
                        return (
                            <Box className={("item" + (item.isMy == true ? " item-my " : ""))}>
                                <div>
                                    <p>{item.message}</p>
                                    <span>{item.date}</span>
                                </div>
                            </Box>
                        )
                    })
                }
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ background: '#fff', width: 'calc(100% - 100px)' }}>
                    <textarea style={{ width: '100%', border: 'none', resize: 'none', padding: '15px', }} placeholder="Your Message"></textarea>
                </Box>
                <Box sx={{ width: '100px', background: '#fff', height: '85px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton aria-label="delete" size="large">
                        <Send />
                    </IconButton>
                </Box>
            </Box>
        </>
    )
}
export default MessageBody;