import { Avatar, Box, Button, IconButton, Modal } from "@mui/material";
import BoxCenter from "./BoxCenter";
import { AlternateEmail, Edit, MailOutline, Padding, Phone } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useState } from "react";
import ConsumerEdit from "../data/ConsumerEdit";
import ModalCloseButton from "./ModalCloseButton";
import MessageBody from "../data/messages/MessageBody";
import LawModal from "./LawModal";

const ConsumerCard = () => {
    const [showModalEditConsumer, setShowModalEditConsumer] = useState(false);
    const [showModalMessage, setShowModalMessage] = useState(false);
    return (
        <>
            <Box sx={{ mt: 3 }}>
                <BoxCenter>
                    <Avatar
                        alt="Remy Sharp"
                        src="https://cdn-icons-png.flaticon.com/512/9187/9187604.png"
                        sx={{ width: 96, height: 96 }}
                    />
                </BoxCenter>
                <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center', alignContent: 'start', alignItems: 'start' }}>
                    <IconButton component={Link} to="#" onClick={(e) => { window.location.href = "tel:0123456789"; e.preventDefault(); }}>
                        <Phone color="warning" />
                    </IconButton>
                    <IconButton component={Link} to="#" onClick={(e) => { window.location.href = 'mailto:test@test.com'; e.preventDefault(); }}>
                        <AlternateEmail color="success" />
                    </IconButton>
                    <IconButton onClick={() => setShowModalMessage(true)}>
                        <MailOutline color="success" />
                    </IconButton>
                    <IconButton onClick={() => setShowModalEditConsumer(true)}>
                        <Edit color="primary" />
                    </IconButton>
                </Box>
                <BoxCenter style={{ marginBottom: '15px', marginTop: '15px' }}>
                    <Button variant="text" component={Link} to="/consumer?id=a1234-5544-5344">Consumer Name</Button>
                </BoxCenter>
            </Box>
            <LawModal state={showModalEditConsumer} setState={setShowModalEditConsumer} >
                <ConsumerEdit />
            </LawModal>
            <LawModal state={showModalMessage} setState={setShowModalMessage} style={{ width: '90%', maxWidth: '800px', padding: '0px' }}>
                <MessageBody />
            </LawModal>
        </>
    )
}
export default ConsumerCard;