import { Button, ButtonGroup } from "@mui/material";
import Card from "../../components/ui/Card";
import TicketCreate from "../../components/data/ticket/TicketCreate";
import TableTickets from "../../components/data/ticket/TableTickets";

const TicketsView = () => {
    return (
        <>
            <Card>
                <ButtonGroup variant="text" aria-label="Basic button group" sx={{ borderRadius: 0, width: '100%', pb: 1, mb: 3 }}>
                    <TicketCreate />
                    <Button>Two</Button>
                    <Button>Three</Button>
                </ButtonGroup>
                <TableTickets />
            </Card>
        </>
    )
}
export default TicketsView;