import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import BoxCenter from "../../ui/BoxCenter";
import { useState } from "react";
import { WarningAmber } from "@mui/icons-material";
import LawModal from "../../ui/LawModal";
const TicketRemove = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button variant="contained" color="success" sx={{ width: '100%', mb: 2 }} onClick={() => setOpen(true)}>Etiketi Sonlandır</Button>
            <LawModal state={open} setState={setOpen}>
                <BoxCenter>
                    <WarningAmber sx={{ fontSize: '70px', mb: 3 }} />
                </BoxCenter>
                <Typography>Etiket sonlandırılacaktır.Devam edilsin mi?</Typography>
                <BoxCenter>
                    <Box>
                        <Button variant="contained" sx={{ width: '100%', mb: 2, mt: 5 }}>Sonlandır</Button>
                    </Box>
                </BoxCenter>
            </LawModal>
        </>
    )
}
export default TicketRemove;