import { Badge, Box, Button, Drawer, IconButton, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useContext, useState } from "react";
import BoxCenter from "../ui/BoxCenter";
import NotificationItems from "./NotificationItems";
import { Link } from "react-router-dom";
import { lawContantsAccount } from "../../layouts/LayoutAccount";

const NavbarNotifications = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    return (
        <>
            <Tooltip title="Messages">
                <IconButton
                    onClick={() => setShowDrawer(true)}
                    size="small"
                >
                    <Badge badgeContent={2} color="secondary">
                        <NotificationsIcon sx={{color:'#0b0b55'}} />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Drawer anchor={'right'} open={showDrawer} onClose={() => setShowDrawer(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        width: '320px',
                    }
                }}
            >
                <Box sx={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto', p: 1 }}>
                    <NotificationItems />
                </Box>
                <BoxCenter style={{ height: 'calc(80px)', background: '#fff', borderTop: '2px solid #000' }}>
                    <Button onClick={() => lawContantsAccount.goTo('/notifications', () => { setShowDrawer(false) })} variant="contained" component={Link} to="/notifications">Alle</Button>
                </BoxCenter>
            </Drawer>
        </>
    )
}
export default NavbarNotifications;