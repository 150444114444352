import { Avatar, Box, Button, TextField } from "@mui/material"
import BoxCenter from "../ui/BoxCenter";
import LawUploadFile from "../elements/LawUploadFile";

const ConsumerEdit = () => {
    return (
        <>
            <Box sx={{ maxWidth: '460px' }}>
                <BoxCenter>
                    <Avatar
                        alt="Remy Sharp"
                        src="https://cdn-icons-png.flaticon.com/512/9187/9187604.png"
                        sx={{ width: 96, height: 96 }}
                    />
                </BoxCenter>
                <BoxCenter style={{ marginBottom: '30px', marginTop: '20px' }}>
                    <LawUploadFile />
                </BoxCenter>
                <TextField fullWidth label="Consmer ID" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Firstname" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Lastname" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Gender" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Telefon" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Telefon 2" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Eposta" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Postcode" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Address" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Vergi Dairesi" variant="standard" sx={{mb:1}} />
                <TextField fullWidth label="Vergi No" variant="standard" sx={{mb:1}} />
                <BoxCenter style={{marginTop:'30px', marginBottom:'30px'}}>
                    <Button variant="contained">Kaydet</Button>
                </BoxCenter>
            </Box>
        </>
    )
}
export default ConsumerEdit;