import { Avatar, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useState } from "react";
import { ChevronRight, Gavel } from "@mui/icons-material";
import { Link } from "react-router-dom";
const columns = [
    { id: 'id', label: 'Id', maxWidth: 30 },
    { id: 'name', label: 'Name', minWidth: 30 },
    { id: 'document', label: 'Dosya', minWidth: 30 },
    { id: 'status', label: 'Status', minWidth: 30 },
    { id: 'update', label: 'Update', minWidth: 30 },
    { id: 'actions', label: 'Actions', minWidth: 'fit-content' }
];
const rows = [];
function create() {
    const buttons = <Box>
        <IconButton component={Link} to="/accountant-register?id=a1234-5544-5344" aria-label="delete" size="small">
            <Gavel />
        </IconButton>
        <IconButton component={Link} to="/accountant-register?id=a1234-5544-5344" aria-label="delete" size="small">
            <ChevronRight />
        </IconButton>
    </Box>
    for (let index = 0; index < 50; index++) {
        rows.push({ id: index + 1, name: 'Müşteri adı soyadı', document: 'Dosya bilgileri', status: 'Actief', update: '01.01.2024', dava: '01.01.2025', buttons })
    }
    return;
}
create();
const TableAccountantRegisters = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 100px)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell key={index} align={column.align}>
                                                {(column.id == 'actions') ? row.buttons : row[column.id]}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
export default TableAccountantRegisters;