import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Modal, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "../../components/ui/Card";
import { People, Send } from "@mui/icons-material";
import MessageBody from "../../components/data/messages/MessageBody";
import LawModal from "../../components/ui/LawModal";
import MessagesUsers from "../../components/data/messages/MessagesUsers";
import { useContext, useEffect, useState } from "react";
import { ContxAppData } from "../../App";
import { useSearchParams } from "react-router-dom";

const MessagesView = () => {
    const {setMessageSelectedUser } = useContext(ContxAppData);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        let _user = searchParams.get("user");
        if (_user) {
            setMessageSelectedUser(_user);
        }
    }, [searchParams])
    const [showModalUsers, setShowModalUsers] = useState(false);

    return (
        <>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                    <Card style={{ height: 'calc(100vh - 175px)', maxHeight: { sm: '500px' }, overflow: 'auto' }}>
                        <MessagesUsers />
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
                    <Box sx={{ position: 'relative' }}>
                        <Box sx={{ position: 'absolute', width: '40px', height: '40px', background: '#fff', right: '20px', top: '-20px', borderRadius: '30px' }}>
                            <IconButton variant="primary" aria-label="delete" onClick={() => setShowModalUsers(true)}>
                                <People />
                            </IconButton>
                        </Box>
                    </Box>
                    <MessageBody />
                </Grid>
            </Grid>
            <LawModal state={showModalUsers} setState={setShowModalUsers} style={{ width: '100%', maxWidth: '480px' }}>
                <MessagesUsers selectedCallback={() => setShowModalUsers(false)} />
            </LawModal>
        </>
    )
}
export default MessagesView;

