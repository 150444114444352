import { Home, Login, KeyboardArrowDown, HelpOutline, PersonOutline, Beenhere, MailOutline, NotificationsNone } from '@mui/icons-material';
import { AppBar, Box, CssBaseline, Drawer, IconButton, Toolbar, Typography, Grid, MenuList, MenuItem, ListItemIcon, ListItemText, Paper, Divider, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemButton } from '@mui/material';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { lawContantsAccount } from '../../layouts/LayoutAccount';
import BoxCenter from './BoxCenter';
const constants = {
    styles: {
        sideBarMenuItem: { borderRadius: '50px', color: '#b8edff', marginBottom: '3px', padding: '10px 15px' },
        sideBarMenuItemActive: { borderRadius: '50px', color: '#b8edff', backgroundColor: '#3a3a77', marginBottom: '3px', padding: '10px 15px' },
        sideBarAccordionMenuItems: { borderRadius: '50px', marginBottom: '3px', padding: '5px 15px' },
        sideBarAccordionMenuItemsActive: { borderRadius: '50px', marginBottom: '3px', padding: '5px 15px', background: '#3a3a77' },
        sideBarAccordionMenuItemsText: { padding: '5px 15px', fontWeight: 600, color: '#b8edff' }
    }
}
const _iconStyles = { mr: 2, color: '#b8edff' };
const _sideBarItems = [
    {
        isDivider: false,
        icon: <Home sx={_iconStyles} />,
        text: 'Home Page',
        url: '/index'
    },
    {
        isDivider: false,
        icon: <Beenhere sx={_iconStyles} />,
        text: 'Tickets',
        url: '/tickets'
    },
    {
        isDivider: false,
        icon: <MailOutline sx={_iconStyles} />,
        text: 'Messages',
        url: '/messages'
    },
    {
        isDivider: false,
        icon: <NotificationsNone sx={_iconStyles} />,
        text: 'Notifications',
        url: '/notifications'
    },
    {
        isDivider: false,
        icon: <Login sx={_iconStyles} />,
        text: 'Lawyer',
        url: null,
        items: [
            {
                text: 'Registers',
                url: '/lawyer-registers'
            }
        ]
    }
    ,
    {
        isDivider: false,
        icon: <Login sx={_iconStyles} />,
        text: 'Accountant',
        url: null,
        items: [
            {
                text: 'Registers',
                url: '/accountant-registers'
            }
        ]
    },
    {
        isDivider: false,
        icon: <PersonOutline sx={_iconStyles} />,
        text: 'Users',
        url: null,
        items: [
            {
                text: 'Consumers',
                url: '/consumers'
            },
            {
                text: 'Accounts',
                url: '/users'
            }
        ]
    },
    {
        isDivider: true
    },
    {
        isDivider: false,
        icon: <HelpOutline sx={{ mr: 2, color: '#b8edff' }} />,
        text: 'Support',
        url: '/support'
    },
]
const Sidebar = (props) => {
    const location = useLocation(); // Mevcut sayfanın konumunu alır
    const handleDrawerToggle = () => {
        props.setMobileOpen(!props.mobileOpen);
    };
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const drawer = (
        <Box sx={{ p: 2 }}>
            <BoxCenter>
                <Typography variant="h4" component="div" sx={{ color: '#fff' }}>Logo</Typography>
            </BoxCenter>

            <Box mt={2}>
                <MenuList>
                    {
                        _sideBarItems.map((item, index) => {
                            return (
                                (item.items && item.items.length > 0) ? (
                                    <Accordion
                                        key={index}
                                        expanded={expanded === ('panel-' + index)} onChange={handleChange(('panel-' + index))}
                                        sx={{
                                            boxShadow: 'none',
                                            '&.Mui-expanded': { margin: 0 },
                                            '&::before': { display: 'none' },
                                            background: 'transparent'
                                        }}>
                                        <AccordionSummary
                                            expandIcon={<KeyboardArrowDown sx={{ color: '#b8edff' }} />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            {item.icon}
                                            <Typography fontWeight={600} color='#b8edff'>{item.text}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pr: 0, pt: 0, }}>
                                            <List sx={{ pl: 3 }}>
                                                {
                                                    item.items.map((itemp, indexp) => {
                                                        return (
                                                            <ListItem key={indexp} disablePadding onClick={() => lawContantsAccount.goTo(itemp.url)}>
                                                                <ListItemButton style={(location.pathname == itemp.url ? constants.styles.sideBarAccordionMenuItemsActive : constants.styles.sideBarAccordionMenuItems)}>
                                                                    <Typography style={constants.styles.sideBarAccordionMenuItemsText}>{itemp.text}</Typography>
                                                                </ListItemButton>
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : ((item.isDivider == true ? (
                                    <Divider sx={{ mb: 2, mt: 2, color: '#fff' }} />
                                ) : (
                                    <MenuItem key={index} onClick={() => lawContantsAccount.goTo(item.url)} style={(location.pathname == item.url ? constants.styles.sideBarMenuItemActive : constants.styles.sideBarMenuItem)} >
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography fontWeight={600}>{item.text}</Typography>
                                        </ListItemText>
                                    </MenuItem>
                                ))
                                )
                            )
                        })
                    }
                </MenuList>
            </Box>
        </Box >
    );
    return (
        <Box
            component="nav"
            sx={{ width: { md: props.drawerWidth }, flexShrink: { md: 0 }, boxShadow: 'none', border: 'none' }}
            aria-label="sidebar folders"
        >
            {/* Mobil Sidebar için Drawer */}
            <Drawer
                variant="temporary"
                open={props.mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Mobil performans için
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, border: 'none', boxShadow: 'none', background: '#0b0b55' },
                }}
            >
                {drawer}
            </Drawer>

            {/* Desktop Sidebar */}
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth, border: 'none', boxShadow: 'none', background: '#0b0b55' },
                }}
                open={props.mobileOpen}
            >
                {drawer}
            </Drawer>
        </Box>
    )
}
export default Sidebar;
