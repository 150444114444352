import { Avatar, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import Card from "../components/ui/Card";
import { useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'role', label: 'Role', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 170 },
    { id: 'actions', label: 'Actions', minWidth: 'fit-content' }
];

function create(name, email, role, status) {
    const buttons = <Box>
        <IconButton component={Link} to="/user?id=a1234-5544-5344" aria-label="delete" size="small">
            <ChevronRight />
        </IconButton>
    </Box>
    return { name, email, role, status, buttons };
}

var rows = [
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief'),
    create('User Name Surname', 'tesst@test.email', 'Role Name', 'actief')
];
const UsersView = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Card>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 100px)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                    {columns.map((column) => {
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {(column.id == 'image') ? (
                                                    row.image
                                                ) : ((column.id == 'actions') ? row.buttons : row[column.id])}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
    )
}
export default UsersView;