import { Box, Modal } from "@mui/material";
import ModalCloseButton from "./ModalCloseButton";

const LawModal = ({children, state, setState, style }) => {
    return (
        <>
            <Modal
                open={state}
                onClose={() => setState(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box className="law-modal" style={{ ...style }}>
                    <ModalCloseButton setState={setState} />
                    {children}
                </Box>
            </Modal>
        </>
    )
}
export default LawModal;