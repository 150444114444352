import React, { useContext, useState } from 'react';
import { Backdrop, Box, CssBaseline, Toolbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import HideOnScrollNavbar from '../components/ui/HideOnScrollNavbar';
import Sidebar from '../components/ui/Sidebar';
const drawerWidth = 320;
export const lawContantsAccount = {
    goTo: () => { }
}
const LayoutAccount = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
    lawContantsAccount.goTo = (url, callback) => {
        navigate(url);
        if (callback) {
            callback();
        }
        if (mobileOpen == true) {
            setMobileOpen(false);
        }
        /*
        >Eğer function kullanmayacaksan sunlari da kullanabilirsin
        <Button component={Link} to="/messages">Messages</Button>
        <Link to="/..."></Link>
        > ortak function calistirmak icin bu yontemi kullandim
        onClick={() => lawContantsAccount.goTo('/messages')}
        */
    }
    return (
        <>
            <Box sx={{ display: 'flex', height: '100vh' }}>
                <CssBaseline />
                <HideOnScrollNavbar drawerWidth={drawerWidth} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
                <Sidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 1,
                        width: { sm: `calc(100vw - ${(drawerWidth)}px)` },
                        maxWidth: '100%',
                        
                    }}
                >
                    <Toolbar /> {/* Navbar ile içeriği hizalamak için */}
                    <Box sx={{ width: '100%', maxWidth: '1500px', margin: 'auto', marginTop: '50px', paddingBottom:'50px' }}>
                        <Outlet /> {/*Pages */}
                    </Box>
                </Box>
            </Box>

        </>
    );
};
export default LayoutAccount;