import './App.css';
import './bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, BrowserRouter, useAsyncError } from 'react-router-dom';
import IndexView from './pages/IndexView';
import LoginView from './pages/LoginView';
import LayoutHome from './layouts/LayoutHome';
import UsersView from './pages/UsersView';
import UserView from './pages/UserView';
import ConsumersView from './pages/ConsumersView';
import ConsumerView from './pages/ConsumerView';
import LayoutAccount from './layouts/LayoutAccount';
import MyAccountView from './pages/MyAccountView';
import AccountantRegistersView from './pages/accountant/AccountantRegistersView';
import LawyerRegistersView from './pages/lawyer/LawyerRegistersView';
import LawyerRegisterView from './pages/lawyer/LawyerRegisterView';
import AccountantRegisterView from './pages/accountant/AccountantRegisterView';
import TicketsView from './pages/app/TicketsView';
import ForgotPasswordView from './pages/home/ForgotPasswordView';
import TicketView from './pages/app/TicketView';
import MessagesView from './pages/app/MessagesView';
import NotificationsView from './pages/app/NotificationsView';
import PageNotFoundView from './pages/generic/PageNotFoundView';
import { createContext, useState } from 'react';
import SupportView from './pages/SopportView';
export const ContxAppData = createContext();
function App() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [messageSelectedUser, setMessageSelectedUser] = useState('');
  return (
    <>
      <ContxAppData.Provider value={{ showDrawer, setShowDrawer, messageSelectedUser, setMessageSelectedUser }}>
        <Router>
          <Routes>
            <Route path='/' element={<LayoutHome />}>
              <Route exact path="/" element={<LoginView />} />
              <Route path="/login" element={<LoginView />} />
              <Route path="/forgot-password" element={<ForgotPasswordView />} />
            </Route>
            <Route path='/' element={<LayoutAccount />}>
              <Route path="/tickets" element={<TicketsView />} />
              <Route path="/ticket" element={<TicketView />} />
              <Route path="/index" element={<IndexView />} />
              <Route path="/my-account" element={<MyAccountView />} />
              <Route path="/users" element={<UsersView />} />
              <Route path="/user" element={<UserView />} />
              <Route path="/consumers" element={<ConsumersView />} />
              <Route path="/consumer" element={<ConsumerView />} />
              <Route path="/accountant-registers" element={<AccountantRegistersView />} />
              <Route path="/accountant-register" element={<AccountantRegisterView />} />
              <Route path="/lawyer-registers" element={<LawyerRegistersView />} />
              <Route path="/lawyer-register" element={<LawyerRegisterView />} />
              <Route path="/messages" element={<MessagesView />} />
              <Route path="/notifications" element={<NotificationsView />} />
              <Route path="/support" element={<SupportView />} />
            </Route>
            <Route path="*" element={<PageNotFoundView />} />
          </Routes>
        </Router>
      </ContxAppData.Provider>
    </>
  );
}

export default App;