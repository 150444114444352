import Grid from "@mui/material/Grid2";
import Card from "../components/ui/Card";
import { Avatar, Box, Button, ButtonGroup, IconButton, List, ListItem, ListItemIcon, ListItemText, Modal, Tab, Tabs, TextField, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import { Cloud, Edit, MailOutline, Phone, Place } from "@mui/icons-material";
import TableAccountantRegisters from "../components/data/accountant/TableAccountantRegisters";
import TableLawyerRegisters from "../components/data/lawyer/TableLawyerRegisters";
import styled from "@emotion/styled";
import ConsumerCard from "../components/ui/ConsumerCard";
import { PieChart } from "@mui/x-charts";
import TicketCreate from "../components/data/ticket/TicketCreate";
import TableTickets from "../components/data/ticket/TableTickets";
const ConsumerView = () => {

    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }} style={{ marginBottom: '30px' }}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                    <Card style={{ minHeight: '300px' }}>
                        <ConsumerCard />
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
                    <Card style={{ minHeight: '300px' }}>
                        <List>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon>
                                    <Phone />
                                </ListItemIcon>
                                <ListItemText primary="06123456789" />
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon>
                                    <MailOutline />
                                </ListItemIcon>
                                <ListItemText
                                    primary="test@email.com"
                                />
                            </ListItem>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon>
                                    <Place />
                                </ListItemIcon>
                                <ListItemText
                                    primary="adres text ...."
                                />
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
            <Card>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: '100%' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Generic" value="1" />
                            <Tab label="Companies" value="2" />
                            <Tab label="Tickets" value="3" />
                            <Tab label="Muhasebe Kayıtları" value="4" />
                            <Tab label="Dava Dosyaları" value="5" />
                        </Tabs>
                    </Box>
                    <TabPanel value="1">
                        <Grid container spacing={0}>
                            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }} sx={{ p: 3 }}>
                                <PieChart
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: 10, label: 'series A' },
                                                { id: 1, value: 15, label: 'series B' },
                                                { id: 2, value: 20, label: 'series C' },
                                            ],
                                            innerRadius: 20,
                                            outerRadius: 70,
                                            paddingAngle: 5,
                                            cornerRadius: 5,
                                            cx: 70,
                                            cy: 80,
                                        },
                                    ]}
                                    width={270}
                                    height={160}
                                />
                                <h5 className="d-block">Tickets</h5>
                                <span className="d-block">Aktif ticket sayısı</span>
                                <span className="d-block">Tamamlanan ticket sayısı</span>
                                <span className="d-block">Son eklenen ticket durumu</span>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }} sx={{ p: 3, borderRight: { lg: '1px solid #ccc' }, borderLeft: { lg: '1px solid #ccc' } }}>
                                <PieChart
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: 10, label: 'series A' },
                                                { id: 1, value: 15, label: 'series B' },
                                                { id: 2, value: 20, label: 'series C' },
                                            ],
                                            innerRadius: 20,
                                            outerRadius: 70,
                                            paddingAngle: 5,
                                            cornerRadius: 5,
                                            cx: 70,
                                            cy: 80,
                                        },
                                    ]}
                                    width={270}
                                    height={160}
                                />
                                <h5 className="d-block">Muhasebe Kayıtları</h5>
                                <span className="d-block">Muhasebe verisi 1</span>
                                <span className="d-block">Muhasebe verisi 2</span>
                                <span className="d-block">Muhasebe verisi 3</span>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }} sx={{ p: 3 }}>
                                <PieChart
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: 10, label: 'series A', color: 'red' },
                                                { id: 1, value: 15, label: 'series B', color: 'orange' },
                                                { id: 2, value: 20, label: 'series C', color: 'yellow' },
                                            ],
                                            innerRadius: 20,
                                            outerRadius: 70,
                                            paddingAngle: 5,
                                            cornerRadius: 5,
                                            cx: 70,
                                            cy: 80,
                                        },
                                    ]}
                                    width={270}
                                    height={160}
                                />
                                <h5 className="d-block">Dava Dosyaları</h5>
                                <span className="d-block">Dava dosyası verisi 1</span>
                                <span className="d-block">Dava dosyası verisi 1</span>
                                <span className="d-block">Dava dosyası verisi 1</span>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <h5>Bir müşteriye ait birden fazla şirket olabilir</h5>
                    </TabPanel>
                    <TabPanel value="3">
                        <ButtonGroup variant="text" aria-label="Basic button group">
                            <TicketCreate  forConsumerId={"Test consumer Name Surname"}/>
                            <Button>Two</Button>
                            <Button>Three</Button>
                        </ButtonGroup>
                        <TableTickets />
                    </TabPanel>
                    <TabPanel value="4">
                        <TableAccountantRegisters />
                    </TabPanel>
                    <TabPanel value="5">
                        <TableLawyerRegisters />
                    </TabPanel>
                </TabContext>
            </Card>

        </>
    )
}
export default ConsumerView;