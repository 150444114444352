// HideOnScrollNavbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Slide, IconButton, useScrollTrigger, Menu, MenuItem, Box, ListItemIcon, Badge, Avatar, ListItem, ListItemAvatar, ListItemText, Button, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { ArrowBack, Home } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import BoxCenter from './BoxCenter';
import NavbarMessages from '../data/NavbarMessages';
import NavbarNotifications from '../data/NavbarNotifications';
function HideOnScroll(props) {
  const { children, window } = props;
  // Scroll tetikleyici; sayfa yukarı çıktığında göster, aşağı indiğinde gizle
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 30,  // 30 piksel kayınca devreye girer
  });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const constants = {
  menuPaper: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  }
}

const HideOnScrollNavbar = (props) => {

  const navigate = useNavigate();
  const [accountMenu, setAccountMenu] = useState(null);
  const [messagesMenu, setMessagesMenu] = useState(null);
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  const openAccountMenu = Boolean(accountMenu);

  const openNotificationsMenu = Boolean(notificationsMenu);
  const handleClick = (event, target) => {
    if (target == 'accountMenu') {
      setAccountMenu(event.currentTarget);
    }
    else if (target == 'messagesMenu') {
      setMessagesMenu(event.currentTarget);
    }
    else if (target == 'notificationsMenu') {
      setNotificationsMenu(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAccountMenu(null);
    setMessagesMenu(null);
    setNotificationsMenu(null);
  };

  const goToBack = () => {
    navigate(-1)
  }


  return (
    <>
      <HideOnScroll {...props}>
        <AppBar
          position="fixed"
          sx={{
            padding: '0px 0px',
            boxShadow: 'none',
            m: 0,
            width: { md: `calc(100% - ${props.drawerWidth}px)` },
            ml: { md: `${props.drawerWidth}px` },
          }}
        >
          <Toolbar sx={{ display: 'block', justifyContent: 'space-between', backgroundColor: '#FFF',boxShadow:'0px 10px 15px -3px rgba(0,0,0,0.1)', pt:'10px', width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box>
                <IconButton
                  color="primary"
                  onClick={goToBack}
                  sx={{ mr: 2 }}
                >
                  <ArrowBack sx={{ color: '#0b0b55' }} />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => { props.setMobileOpen(!props.mobileOpen) }}
                  sx={{ display: { md: 'none' }, mr: 2 }}
                >
                  <MenuIcon sx={{ color: '#0b0b55' }} />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  component={Link}
                  to="/index"
                  sx={{ mr: 2 }}
                >
                  <Home sx={{ color: '#0b0b55' }} />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <NavbarMessages />
                <NavbarNotifications />

                <Tooltip title="Account settings">
                  <IconButton
                    onClick={(e) => handleClick(e, 'accountMenu')}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={openAccountMenu ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAccountMenu ? 'true' : undefined}
                  >
                    <Avatar alt="User Name" src="https://cdn-icons-png.flaticon.com/512/9187/9187604.png" sx={{ width: 32, height: 32 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <Menu
        anchorEl={accountMenu}
        id="account-menu"
        open={openAccountMenu}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: constants.menuPaper,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose} component={Link} to="/my-account">
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/login">
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={notificationsMenu}
        id="account-menu"
        open={openNotificationsMenu}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: constants.menuPaper,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <BoxCenter>
          <Button variant="contained">Alle</Button>
        </BoxCenter>
      </Menu>

    </>
  );
};

export default HideOnScrollNavbar;
