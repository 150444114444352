import Card from "../../components/ui/Card";
import AccountantEdit from "../../components/data/accountant/AccountantEdit";
import { Button, ButtonGroup } from "@mui/material";
import { Gavel } from "@mui/icons-material";

const AccountantRegisterView = () => {
    return (
        <Card>
            <ButtonGroup variant="text" aria-label="Basic button group">
                <Button><Gavel /> Avukata Gönder</Button>
                <Button>Two</Button>
                <Button>Three</Button>
            </ButtonGroup>
            <AccountantEdit />
        </Card>
    )
}
export default AccountantRegisterView;