import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useContext } from "react";
import { ContxAppData } from "../../../App";
import { lawContantsAccount } from "../../../layouts/LayoutAccount";

const _messages = [
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    },
    {
        username: 'dfghf dh fhfgh',
        message: 'dfgdf hfg hgf jgf jgf jgf jghf jf dfshdf hgf j',
        date: '01.01.2024'
    }
]
const MessagesUsers = ({ selectedCallback }) => {
    const selectUser = (userId) => {
        lawContantsAccount.goTo('/messages?user=user-id-' + userId);
        if (selectedCallback) {
            selectedCallback();
        }
    }
    return (
        <List sx={{ width: '100%' }}>
            {
                _messages.map((item, index) => {
                    return (
                        <>
                            <ListItemButton alignItems="flex-start" onClick={() => { selectUser(index) }}>
                                <ListItemAvatar>
                                    <Avatar alt={("User Name surname " + index)} src="/static/images/avatar/1.jpg" />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={("User Name surname " + index)}
                                    secondary={
                                        <>
                                            <Typography
                                                component="span"
                                                variant="body4"
                                                sx={{ color: 'text.primary', display: 'block' }}
                                            >
                                                Message content text ....
                                            </Typography>
                                            <Typography
                                                component="small"
                                                variant="body4"
                                                sx={{ color: 'text.primary', display: 'block' }}
                                            >
                                                01.01.2000
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItemButton>
                            <Divider variant="inset" component="li" />
                        </>
                    )
                })
            }
        </List>
    )
}
export default MessagesUsers;