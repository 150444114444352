import { Box, Button, Tab, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Card from "../components/ui/Card";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TodoList from "../components/data/TodoList";
import { AcUnit, Cloud, Mail, Thunderstorm, WbSunny } from "@mui/icons-material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from "react-router-dom";
import TableTickets from "../components/data/ticket/TableTickets";
import BoxCenter from "../components/ui/BoxCenter";
import WeatherCard from "../components/data/WeatherCard";

const IndexView = () => {
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
                    <Card>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid size={{ xs: 12, sm: 6, md: 8 }}>
                                <h3 style={{ color: '#b1b1b1' }}>Welkom</h3>
                                <h5 style={{ color: '#b1b1b1' }}>ABC1234</h5>
                                <span>You have done 72% more sales today. Check your new badge in your profile.</span>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 4 }}>
                                <img style={{ width: '100%', height: 'auto' }} src="https://img.freepik.com/free-vector/character-daydreaming-instead-working_23-2148498570.jpg?t=st=1730815332~exp=1730818932~hmac=3bdaede5bc9072c8b6b40ec1b5af948f372aaae232caaf81d56ce4b9b5ce147f&w=1380" />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                    <WeatherCard/>
                </Grid>
            </Grid>
            <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8 }}>
                    <Card>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Tickets" value="1" />
                                    <Tab label="Messages" value="2" />
                                    <Tab label="Item Three" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <TableTickets />
                            </TabPanel>
                            <TabPanel value="2">Item Two</TabPanel>
                            <TabPanel value="3">Item Three</TabPanel>
                        </TabContext>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} sx={{ marginBottom: '20px' }}>
                        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                            <Card>
                                <Mail sx={{ color: '#ada9fe', fontSize: '40px' }} />
                                <Typography>Messages</Typography>
                                <Typography fontSize={40} fontWeight={600} color="#b1b1b1">13</Typography>
                                <Button variant="outlined" component={Link} to="/messages" sx={{ mb: 3 }}>Show</Button>
                            </Card>
                        </Grid>
                        <Grid size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                            <Card>
                                <NotificationsIcon sx={{ color: '#fda702', fontSize: '40px' }} />
                                <Typography>Notifications</Typography>
                                <Typography fontSize={40} fontWeight={600} color="#b1b1b1">3</Typography>
                                <Button variant="outlined" component={Link} to="/notifications" sx={{ mb: 3 }}>Show</Button>
                            </Card>
                        </Grid>
                    </Grid>
                    <Card>
                        <TodoList />
                    </Card>
                </Grid>
            </Grid>
            {/*<PdfViewer/> */}
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid size={{ xs: 4, sm: 8, md: 12 }}>
                    <Card>
                        <Typography>size=8</Typography>
                    </Card>
                </Grid>
                <Grid size={4}>
                    <Card>
                        <Typography>size=4</Typography>
                    </Card>
                </Grid>
                <Grid size={4}>
                    <Card>
                        <Typography>size=4</Typography>
                    </Card>
                </Grid>
                <Grid size={8}>
                    <Card>
                        <Typography>size=8</Typography>
                    </Card>
                </Grid>
            </Grid>
            <h2>Index</h2>
        </>
    )
}
export default IndexView;