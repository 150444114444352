import { Box, Button, TextField } from "@mui/material";
import Card from "../../components/ui/Card";
import BoxCenter from "../../components/ui/BoxCenter";
import { LockReset } from "@mui/icons-material";
import Link2 from "../../components/ui/Link2";

const ForgotPasswordView = () => {
    return (
        <BoxCenter style={{ height: '100vh', width: '100vw' }}>
            <Box sx={{ maxWidth: '360px', width: '100%' }}>
                <Card style={{ paddingBottom: '40px' }}>
                    <BoxCenter>
                        <LockReset sx={{ fontSize: '80px', color: '#ccc' }} />
                    </BoxCenter>
                    <TextField label="E-mail" variant="standard" sx={{ width: '100%', mb: 3, mt: 5 }} />
                    <BoxCenter>
                        <Button variant="contained">Reset</Button>
                    </BoxCenter>
                    <Link2 to={"/login"} text={"Log in"} style={{marginTop:'20px'}} />
                </Card>
            </Box>
        </BoxCenter>
    )
}
export default ForgotPasswordView;