import { Avatar, Box, Button, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import Card from "../components/ui/Card";
import { Cloud, Logout, Visibility, VisibilityOff } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import { Link } from "react-router-dom";

const MyAccountView = () => {
    const [value, setValue] = useState('1');
    const [role, setRole] = useState(0);
    const [status, setStatus] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };
    const handleChangeRole = (event) => {
        setRole(event.target.value);
    };
    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };
    return (
        <>
            <Card>
                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', mb: 5 }}>
                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://cdn-icons-png.flaticon.com/512/9187/9187604.png"
                            sx={{ width: 56, height: 56, mr: 3 }}
                        />
                        <Box>
                            <Box>
                                <h3 style={{ margin: 0 }}>My Name surname</h3>
                                <small>My Role Name</small>
                            </Box>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<Cloud />}
                            >
                                Chang Image
                                <input
                                    style={{
                                        clip: 'rect(0 0 0 0)',
                                        clipPath: 'inset(50%)',
                                        height: 1,
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        whiteSpace: 'nowrap',
                                        width: 1,
                                    }}
                                    type="file"
                                    onChange={(event) => console.log(event.target.files)}
                                    multiple
                                />
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <IconButton component={Link} to="/login">
                            <Logout />
                        </IconButton>
                    </Box>
                </Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Data" value="1" />
                            <Tab label="Password" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box sx={{ maxWidth: '440px' }}>

                            <TextField label="User Name" variant="standard" sx={{ width: '100%', mb: 1 }} />
                            <TextField label="Name Surname" variant="standard" sx={{ width: '100%', mb: 1 }} />
                            <TextField label="E-mail" variant="standard" sx={{ width: '100%', mb: 1 }} />
                            <TextField label="Phone" variant="standard" sx={{ width: '100%', mb: 1 }} />
                            <FormControl variant="standard" sx={{ width: '100%', mb: 1 }}>
                                <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={role}
                                    onChange={handleChangeRole}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ width: '100%', mb: 1 }}>
                                <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={status}
                                    onChange={handleChangeStatus}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained">Change Data</Button>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box sx={{ maxWidth: '440px' }}>
                            <FormControl sx={{ width: '100%', mb: 5 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Current Password</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={
                                                    showPassword ? 'hide the password' : 'display the password'
                                                }
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%', mb: 5 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={
                                                    showPassword ? 'hide the password' : 'display the password'
                                                }
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%', mb: 5 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Password Re-renter</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={
                                                    showPassword ? 'hide the password' : 'display the password'
                                                }
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained">Change Password</Button>
                            </Box>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Card>
        </>
    )
}
export default MyAccountView;