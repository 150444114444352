import { Box, TextField } from "@mui/material";

const AccountantEdit = () => {
    return (
        <>
            <Box sx={{maxWidth:'480px',width:'100%'}}>
                <TextField fullWidth sx={{mb:1}} id="standard-basic" label="Data 1" variant="standard" />
                <TextField fullWidth sx={{mb:1}} id="standard-basic" label="Data 2" variant="standard" />
                <TextField fullWidth sx={{mb:1}} id="standard-basic" label="Data 3" variant="standard" />
                <TextField fullWidth sx={{mb:1}} id="standard-basic" label="Data 4" variant="standard" />
                <TextField fullWidth sx={{mb:1}} id="standard-basic" label="Data 5" variant="standard" />
            </Box>
        </>
    )
}
export default AccountantEdit;