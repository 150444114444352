const CssChart = () => {
    return (
        <div class="ac-single-chart">
            <svg viewBox="-2 -2 42 42" class="ac-circular-chart success">
                <path class="ac-circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                <path class="ac-circle" stroke-dasharray="35, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
            </svg>
        </div >
    )
}
export default CssChart;