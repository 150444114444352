import { Alert, AlertTitle, Avatar, Badge, Box, Button, ButtonGroup, Drawer, IconButton, ListItemAvatar, ListItemText, MenuItem, Modal, TextField, Tooltip, Typography } from "@mui/material";
import Card from "../ui/Card";
import MailIcon from '@mui/icons-material/Mail';
import { useContext, useState } from "react";
import BoxCenter from "../ui/BoxCenter";
import { ChevronRight, ForwardToInbox, MailOutline, Reply, Send, SendOutlined, SendSharp } from "@mui/icons-material";
import ModalCloseButton from "../ui/ModalCloseButton";
import { lawContantsAccount } from "../../layouts/LayoutAccount";
import LawModal from "../ui/LawModal";
import { ContxAppData } from "../../App";

const _messages = [
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    },
    {
        header: 'Brunch this weekend?',
        description: 'Ali Connors',
        date: '01.01.2024',
        username: ''
    }
];

const NavbarMessages = () => {
    const [showMdlMessageDetails, setShowMdlMessageDetails] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const showMessageDetails = (id) => {
        setShowMdlMessageDetails(true);
    }
    return (
        <>
            <Tooltip title="Messages">
                <IconButton
                    onClick={() => setShowMessages(true)}
                    sx={{color:'#0b0b55'}}
                    size="small"
                >
                    <Badge badgeContent={2} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Drawer anchor={'right'} open={showMessages} onClose={() => setShowMessages(false)}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        width: '320px',
                    }
                }}
            >
                <Box sx={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto', p: 1 }}>
                    {
                        _messages.map((item, index) => {
                            return (
                                <>
                                    <Alert
                                        key={index}
                                        icon={
                                            <MailOutline sx={{display:'none'}} />
                                        }
                                        action={
                                            <>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => showMessageDetails(1)}
                                                    >
                                                        <Reply fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => lawContantsAccount.goTo(('/messages?user=user-id' + index), () => setShowMessages(false))}
                                                    >
                                                        <ForwardToInbox fontSize="inherit" />
                                                    </IconButton>
                                                </Box>
                                            </>
                                        }
                                        sx={{ mb: 2 }}
                                        severity={item.type}
                                    >
                                        <AlertTitle sx={{ fontSize: 15, fontWeight: 600 }}>{item.header}</AlertTitle>
                                        <Typography sx={{ p: 0, fontSize: 14 }}>{item.description}</Typography>
                                        <Typography sx={{ fontSize: 12 }}>{item.date}</Typography>
                                    </Alert>
                                </>
                            )
                        })
                    }
                </Box>
                <BoxCenter style={{ height: 'calc(80px)', background: '#fff', borderTop: '2px solid #000' }}>
                    <Button onClick={() => lawContantsAccount.goTo('/messages', () => setShowMessages(false))} variant="contained">Alle</Button>
                </BoxCenter>
            </Drawer>
            <LawModal state={showMdlMessageDetails} setState={setShowMdlMessageDetails}>
                <Box>
                    <Typography>User Name Surname</Typography>
                    <Typography>01.01.2024</Typography>
                    <Typography>Message content ....</Typography>
                </Box>
                <TextField
                    sx={{ width: '100%', mt: 5, mb: 3 }}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    label={"Your message"}
                />
                <BoxCenter>
                    <Button variant="contained" endIcon={<SendOutlined />}>
                        Send
                    </Button>
                </BoxCenter>
            </LawModal>
        </>
    )
}
export default NavbarMessages;