import Card from "../../components/ui/Card";
import TableLawyerRegisters from "../../components/data/lawyer/TableLawyerRegisters";

const LawyerRegistersView=()=>{
    return (
        <Card>
            <TableLawyerRegisters/>
        </Card>
    )
}
export default LawyerRegistersView;