import { Card } from "@mui/material";
import NotificationItems from "../../components/data/NotificationItems";
const NotificationsView = () => {
    return (
        <>
            <Card style={{padding:'20px', maxWidth:'800px', margin:'auto'}}>
                <NotificationItems />
            </Card>
        </>
    )
}
export default NotificationsView;