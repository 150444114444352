import { Box } from "@mui/material";
import BoxCenter from "../ui/BoxCenter";
import { AcUnit, Cloud, Margin, Thunderstorm, WbSunny } from "@mui/icons-material";

const WeatherCard = () => {
    return (
        <Box sx={{ minHeight: '230px', padding: '10px', borderRadius: '15px', backgroundSize: 'cover', backgroundImage: 'url(https://img.freepik.com/free-vector/meadow-with-pond-conifers-hills-night_107791-10085.jpg?t=st=1732119083~exp=1732122683~hmac=1c0c86cd811c6b16c4d304c631cbfdc5283a8934d8ca85350e644597a8dbce48&w=1480)' }}>
            <Box sx={{ width: '100%', display: 'block', maxWidth: '500px', margin: 'auto' }}>
                <span style={{ fontSize: '50px', display: 'block', textAlign: 'end', width: '100%', color: '#fff', fontWeight: '600' }}>19:17</span>
                <span style={{ fontSize: '15px', display: 'block', textAlign: 'end', width: '100%', color: '#fff', fontWeight: '600' }}>20 Spt. 2024 Modany</span>
                <Box sx={{ display: 'flex', paddingTop: '30px' }}>
                    <Box sx={{ width: '20%' }}>
                        <BoxCenter>
                            <WbSunny sx={{ color: 'yellow' }} fontSize="large" />
                        </BoxCenter>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>Mon</span>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>26°C</span>
                    </Box>
                    <Box sx={{ width: '20%' }}>
                        <BoxCenter>
                            <Cloud sx={{ color: '#fff' }} fontSize="large" />
                        </BoxCenter>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>Mon</span>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>26°C</span>
                    </Box>
                    <Box sx={{ width: '20%' }}>
                        <BoxCenter>
                            <Thunderstorm sx={{ color: '#fff' }} fontSize="large" />
                        </BoxCenter>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>Mon</span>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>26°C</span>
                    </Box>
                    <Box sx={{ width: '20%' }}>
                        <BoxCenter>
                            <WbSunny sx={{ color: 'yellow' }} fontSize="large" />
                        </BoxCenter>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>Mon</span>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>26°C</span>
                    </Box>
                    <Box sx={{ width: '20%' }}>
                        <BoxCenter>
                            <AcUnit sx={{ color: '#fff' }} fontSize="large" />
                        </BoxCenter>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>Mon</span>
                        <span style={{ width: '100%', display: 'block', textAlign: 'center', color: '#fff' }}>26°C</span>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default WeatherCard;